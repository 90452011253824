(function($, undefined){

    $(document).ready(function() {
        $('.js-coopSlider').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5355 1.46447C40.4882 3.41709 40.4882 6.58291 38.5355 8.53553L12.0711 35L38.5355 61.4645C40.4882 63.4171 40.4882 66.5829 38.5355 68.5355C36.5829 70.4882 33.4171 70.4882 31.4645 68.5355L1.46447 38.5355C-0.488155 36.5829 -0.488155 33.4171 1.46447 31.4645L31.4645 1.46447C33.4171 -0.488155 36.5829 -0.488155 38.5355 1.46447Z" fill="black"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M1.46447 1.46447C-0.488152 3.41709 -0.488152 6.58291 1.46447 8.53553L27.9289 35L1.46447 61.4645C-0.488155 63.4171 -0.488152 66.5829 1.46447 68.5355C3.41709 70.4882 6.58292 70.4882 8.53554 68.5355L38.5355 38.5355C40.4882 36.5829 40.4882 33.4171 38.5355 31.4645L8.53554 1.46447C6.58292 -0.488155 3.41709 -0.488155 1.46447 1.46447Z" fill="black"/> </svg></span>',
        });
        $('.js-revsSlider').slick({
            prevArrow: '<span class="slick-arrow arrow-prev"><svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5355 1.46447C40.4882 3.41709 40.4882 6.58291 38.5355 8.53553L12.0711 35L38.5355 61.4645C40.4882 63.4171 40.4882 66.5829 38.5355 68.5355C36.5829 70.4882 33.4171 70.4882 31.4645 68.5355L1.46447 38.5355C-0.488155 36.5829 -0.488155 33.4171 1.46447 31.4645L31.4645 1.46447C33.4171 -0.488155 36.5829 -0.488155 38.5355 1.46447Z" fill="#8dc63f"/> </svg></span>',
            nextArrow: '<span class="slick-arrow arrow-next"><svg width="40" height="70" viewBox="0 0 40 70" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M1.46447 1.46447C-0.488152 3.41709 -0.488152 6.58291 1.46447 8.53553L27.9289 35L1.46447 61.4645C-0.488155 63.4171 -0.488152 66.5829 1.46447 68.5355C3.41709 70.4882 6.58292 70.4882 8.53554 68.5355L38.5355 38.5355C40.4882 36.5829 40.4882 33.4171 38.5355 31.4645L8.53554 1.46447C6.58292 -0.488155 3.41709 -0.488155 1.46447 1.46447Z" fill="#8dc63f"/> </svg></span>',
        });

        $('.js-hMenuBtn').click(function(){
            $(this).toggleClass('active')
            $('.js-hMenu').slideToggle();
        });

        $(".fancybox").fancybox();

        $( '.js-modlBtn' ).click( function(e) {
            e.preventDefault()
            if ( $('.js-hidModlTtl').html() ) {
                $('.js-modlTtl').html( $('.js-hidModlTtl').html() )
            }
            if ( $('.js-hidModlTxt').html() ) {
                $('.js-modlTxt').html( $('.js-hidModlTxt').html() )
            }
            if ( $('.js-hidModlImg').html() ) {
                $('.js-modlImg').html( $('.js-hidModlImg').html() )
            }
            $('#modlWindow').fadeIn()
        });

        $('.js-modlCls').click(function(){
            $( '#modlWindow' ).fadeOut()
        })

        $( '.js-modlBtn2' ).click( function(e) {
            e.preventDefault()
            $('#modlWindow2').fadeIn()
        });

        $('.js-modlCls2').click(function(){
            $( '#modlWindow2' ).fadeOut()
        })

        $('.js-revsTabs').click(function(e){
            e.preventDefault()
            $('.js-revsTabs').removeClass('active')
            $(this).addClass('active')
            $('.js-revsTabsCont').removeClass('active')
            $($(this).attr('href')).addClass('active')
        })

        $( '.js-reverseCard' ).click( function(e) {
            e.preventDefault()
            if ($(window).width() <= 639) {
                $(this).closest('.js-cardBl').addClass('reverse')
            }
        });

        $( '.js-cardBack' ).click( function(e) {
            e.preventDefault()
            if ($(window).width() <= 639) {
                $('.js-cardBl').removeClass('reverse')
            }
        });
    });

})(jQuery);
